import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../Logistics/Logistics.scss";

import ServiceToolbar from "../ServiceToolbar";

const TerminalManagement = () => {
  return (
    <>
      <div className="logistics-con">
     <div>
      <p className="title">
        <StaticImage
          className="service-badge"
          src="../../../images/svg/terminal-management.svg"
          width={45}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="badge"
        />
        Terminal Management
        </p>
     </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/port-terminal.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                {/* <p className="label">Stevedoring & Arrastre</p> */}
                <p>
                We make sure that any facility that we engage is able to live up to its utmost potential.
                </p>
                <br/>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ServiceToolbar />
    </>
  );
};

export default TerminalManagement;
